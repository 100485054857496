import { useState } from 'react';
import { ApolloProvider } from '@apollo/client';
import { User } from 'firebase/auth';
import { apolloClient } from '../config';
import Login from './Login';
import UserStats from './UserStats';

function App() {
   const [signedInUser, setSignedInUser] = useState<User | null>(null);

   return (
      <ApolloProvider client={apolloClient}>
         <Login signedInUser={signedInUser} setSignedInUser={setSignedInUser} />
         <UserStats signedInUser={signedInUser} />
      </ApolloProvider>
   );
}

export default App;
