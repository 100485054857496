import { User as SignedInUser } from 'firebase/auth';
import { gql, useQuery } from '@apollo/client';
import { ComputedDatum, ResponsiveSunburst } from '@nivo/sunburst';
import { Grid } from '@mui/material';
import { StatAggregate } from '../../../functions/src/graphql/types';

type StatAggregateOutput = {
   userStatAggregates: StatAggregate;
};

const getStatAggregateQuery = gql`
   query UserStatAggregates($uid: String!) {
      userStatAggregates(uid: $uid) {
         id
         totalAttempts
         totalCompletedAttempts
         totalRealMillis
         statAggregates {
            id
            totalAttempts
            totalCompletedAttempts
            totalRealMillis
            statAggregates {
               id
               totalAttempts
               totalCompletedAttempts
               totalRealMillis
               statAggregates {
                  id
                  totalAttempts
                  totalCompletedAttempts
                  totalRealMillis
               }
            }
         }
      }
   }
`;

const formatData = (object: any): any => {
   return Array.isArray(object)
      ? object.map(formatData)
      : object && typeof object === 'object'
      ? Object.fromEntries(
           Object.entries(object)
              .filter(([k, v]) => {
                 if (object.statAggregates && object.statAggregates.length > 0) {
                    return k === 'statAggregates' || k === 'id';
                 } else {
                    return true;
                 }
              })
              .map(([k, v]) => [k === 'statAggregates' ? 'children' : k, formatData(v)])
        )
      : object;
};

const CustomTooltip = ({ id, value, percentage }: ComputedDatum<unknown>) => {
   return (
      <strong>
         {id}: {value}, {percentage.toFixed(2)}%
      </strong>
   );
};

interface UserProps {
   signedInUser: SignedInUser | null;
}

const User = (props: UserProps) => {
   const {
      loading: aggregatesLoading,
      error: aggregatesError,
      data: statAggregatesData,
   } = useQuery<StatAggregateOutput>(getStatAggregateQuery, {
      skip: !props.signedInUser,
      variables: { uid: props.signedInUser?.uid ?? '' },
   });

   const formattedData = {
      children: [...formatData(statAggregatesData?.userStatAggregates?.statAggregates || [])],
   };

   if (aggregatesError) return <div>`Error: {aggregatesError.message}`</div>;
   if (aggregatesLoading) return <div>Loading...</div>;
   return (
      <>
         <Grid container direction="row" justifyContent="center">
            <Grid item>
               <div style={{ height: 800, width: 800 }}>
                  <ResponsiveSunburst
                     data={formattedData}
                     margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
                     id="id"
                     value={'totalRealMillis'}
                     tooltip={CustomTooltip}
                     cornerRadius={2}
                     enableArcLabels={true}
                  />
               </div>
            </Grid>
            <Grid item>
               <pre>{JSON.stringify(statAggregatesData?.userStatAggregates || 'undefined', null, 2)}</pre>
            </Grid>
         </Grid>
      </>
   );
};

export default User;
