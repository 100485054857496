import React from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { GoogleAuthProvider, signInWithRedirect, User } from 'firebase/auth';
import { auth } from '../config';

const provider = new GoogleAuthProvider();

type LoginProps = {
   signedInUser: User | null;
   setSignedInUser: React.Dispatch<React.SetStateAction<User | null>>;
};

const Login = (props: LoginProps) => {
   auth.onAuthStateChanged((user) => {
      props.setSignedInUser(user);
   });

   return props.signedInUser ? (
      <Grid container direction="row" alignItems={'center'}>
         <Typography>{`Signed in as ${props.signedInUser.displayName ?? props.signedInUser.email}`}</Typography>
         <Button onClick={() => auth.signOut()}>Sign Out</Button>
      </Grid>
   ) : (
      <Button
         onClick={() => {
            signInWithRedirect(auth, provider).catch((error) => {
               console.log(error.message);
            });
         }}
      >
         Sign in with Google
      </Button>
   );
};

export default Login;
