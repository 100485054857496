import { FirebaseOptions, initializeApp } from 'firebase/app';
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import { ApolloClient, InMemoryCache } from '@apollo/client';

const firebaseConfig: FirebaseOptions = {
   apiKey: process.env.REACT_APP_API_KEY,
   authDomain: process.env.REACT_APP_AUTH_DOMAIN,
   projectId: process.env.REACT_APP_PROJECT_ID,
   storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
   messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
   appId: process.env.REACT_APP_APP_ID,
   measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);
if (process.env.REACT_APP_ENV === 'emulator') connectAuthEmulator(auth, 'http://localhost:9099');

const apolloClient = new ApolloClient({
   uri: process.env.REACT_APP_GQL_URI,
   cache: new InMemoryCache({}),
});

export { auth, apolloClient };
